import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import store from '@/store';

export interface Client {
  organisation: string;
  code: string;
  id: string;
  tier: number;

  primaryUser?: string;
  primaryDomain: string;

  archived: boolean;
  searchString?: string;
}

export interface ClientsState {
  clients?: Client[];
  client?: object;
}

export interface ClientApiResponse {
  items: Client[];
}

export const clients: Module<ClientsState, RootState> = {
  namespaced: true,
  state: {
    clients: [],
    client: {},
  },
  getters: {
    clients: (state) => state.clients,
    client: (state) => state.client,
    getClientByCode: (state) => (filteredClients) => {
      return filteredClients.map((code) => {
        return state.clients!.find((client) => client.code === code);
      });
    },
  },
  mutations: {
    setClients: (state, payload: Client[]) => {
      // eslint-disable-next-line no-param-reassign
      state.clients = payload;
    },
    setNewClient: (state, payload: {}) => {
      // eslint-disable-next-line no-param-reassign
      state.client = payload;
    },
    clearNewClient: (state, payload: {}) => {
      // eslint-disable-next-line no-param-reassign
      state.client = payload;
    },
  },
  actions: {
    getClientsFromAPI: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data: allClients }: { data: Client[] } = await publiftApi.get(`/webapi/tenants/${tenantId}/client`);

      const sortedClients = allClients
        .sort((userA, userB) => {
          return userA.code.toLowerCase() < userB.code.toLowerCase() ? -1 : 1;
        })
        .map((client) => ({
          ...client,
          searchString: `${client.code}`, // this is used in the filtering component.
        }));
      commit('setClients', sortedClients);
      return;
    },
    /**
     * set the newly created client data to state
     */
    setNewClientData: async ({ commit }, data) => {
      commit('setNewClient', data);
      return;
    },
    clearNewClientData: async ({ commit }) => {
      commit('clearNewClient', {});
      return;
    },
  },
};
