<template>
  <div :style="styles" :class="classes" class="v-offset">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: [Number, String],
      default: 0,
    },
    topRelative: {
      type: [Number, String],
    },
  },

  computed: {
    classes(): any {
      return {
        'v-offset--full-width': this.fullWidth,
      };
    },
    styles(): any {
      return {
        top: `${this.topRelative || this.offset}px`,
        marginBottom: `${this.offset}px`,
      };
    },
  },
});
</script>

<style>
.v-offset {
  margin: 0 auto;
  max-width: calc(100% - 32px);
  position: relative;
}
.v-offset--full-width {
  max-width: 100%;
}
</style>
