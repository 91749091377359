import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import store from '../../store';

export interface UserApiResponse {
  items: User[];
}

export interface User {
  auth0Id?: string;
  email: string;
  password?: string;
  permissions?: string[];
  role?: string;
  accounts?: string[];
  clients?: string[];
  networks?: string[];

  // legacy
  unsubscribecode?: string;
  extendedSession?: boolean;
  currency?: string;
  // products?: string[];

  // name
  firstName?: string;
  lastName?: string;

  // id
  _id?: string;

  // status
  archived?: boolean;
  activated: boolean;

  // services settings
  products?: {
    dashboard?: {
      services: string[];
      reportingCurrency: string;
      enabled: boolean;
    };
    extension?: {
      services: string[];
      enabled: boolean;
    };
  };

  // email data
  emailData?: {
    resetPasswordToken?: string;
    resetPasswordExpires?: number;
    activationToken?: string;
  };
}

export interface Auth0User {
  name: string;
  email: string;
  user_id: string;
}

export interface Role {
  name?: string;
  id: string;
  description: string;
  code: string;
}

export const dashboardServices = [
  {
    name: 'Weekly Reports',
    code: 'weeklyreports',
  },
  {
    name: 'Campaigns',
    code: 'campaigns',
  },
  {
    name: 'Tickets',
    code: 'tickets',
  },
  {
    name: 'Holistics (Beta)',
    code: 'holisticsbeta',
  },
];

export const extensionServices = [
  {
    name: 'Blocking',
    code: 'blocking',
  },
  {
    name: 'Debug',
    code: 'debug',
  },
];

export interface UsersState {
  users?: User[];
  auth0TagUsers?: Auth0User[];
  roles?: Role[];
  userRole?: Role[];
}

export const users: Module<UsersState, RootState> = {
  namespaced: true,
  state: {
    users: [],
    auth0TagUsers: [],
    roles: [],
    userRole: [],
  },
  getters: {
    users: (state) => state.users,
    auth0TagUsers: (state) => state.auth0TagUsers,
    roles: (state) => state.roles,
    getUserRole: (state) => state.userRole,
    getUserByEmail: (state) => (filteredAccounts) => {
      return filteredAccounts.map((account) => {
        return state.users!.find((user) => user.email === account);
      });
    },
    getUserByAccount: (state) => (filteredAccounts) => {
      return filteredAccounts.map((code) => {
        return state.users!.filter((user) => {
          return user.accounts!.includes(code.toLowerCase());
        });
      });
    },
    getUserByClientCode: (state) => (filteredAccounts) => {
      return filteredAccounts.map((code) => {
        return state.users!.filter((user) => {
          return user.clients!.includes(code);
        });
      });
    },
  },
  mutations: {
    setUsers: (state, payload: User[]) => {
      state.users = payload;
    },
    setAuth0TagUsers: (state, payload: Auth0User[]) => {
      state.auth0TagUsers = payload;
    },
    setRoles: (state, payload) => {
      state.roles = payload;
    },
    setUserRole: (state, payload) => {
      state.userRole = payload;
    },
  },
  actions: {
    getUsersFromAPI: async ({ commit }, { showArchived = true, showInactive = true }) => {
      const { data: allUsers }: { data: User[] } = await publiftApi.get('/v2/users/', {
        params: {
          showArchived,
          showInactive,
          limit: 100000,
        },
      });
      // Sorts users alphabetically by their email.
      const sortedUsers = allUsers
        .sort((userA, userB) => {
          return userA.email < userB.email ? -1 : 1;
        })
        .map((user) => ({
          ...user,
          searchString: `${user.email} (${user.firstName}) (${user.lastName})`, // this is used in the filtering component.
        }));
      commit('setUsers', sortedUsers);
    },
    getAuth0TagUsersFromApi: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];
      const auth0TagUsers = await publiftApi.get<Auth0User[]>(`/webapi/tenants/${tenantId}/users/auth0/tag-users`);
      commit('setAuth0TagUsers', auth0TagUsers.data);
    },
    getRolesFromApi: async ({ commit }) => {
      try {
        const { data: roles }: { data: Role[] } = await publiftApi.get('/v2/roles');
        commit('setRoles', roles);
      } catch (e) {
        console.log('Unable to fetch roles');
      }
    },
    getUserRoleFromApi: async ({ commit }, payload) => {
      try {
        const role = await publiftApi.get(`/v2/users/${payload}/roles`);
        commit('setUserRole', (role.data[0] as Role).id);
      } catch (e) {
        console.log('Unable to fetch user roles');
      }
    },
    setUserRole: async ({ commit }, payload) => {
      commit('setUserRole', payload);
    },
  },
};
