import Vue from 'vue';
import Vuetify from 'vuetify/lib';

const theme = {
  accent: '#2d2d2d',
  secondary_accent: '#A0A0A0',
  tertiary_accent: '#aaaaaa',
  primary: '#ff4069',
  secondary: '#f16f90',
  tertiary: '#f9bfce',
  error: '#BF360C',
  info: '#546E7A',
  success: '#69F0AE',
  warning: '#FFE082',
  toolbar: '#2d2d2d',
  sidebar: '#424242',
  card_top: '#424242',
  dialog_top: '#424242',
  tile_top: '#424242',
  tile_icon: '#737373',
  filter_btn: '#ff4069',
};

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: theme,
    },
  },
};
export default new Vuetify(opts);
