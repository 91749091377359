import store from '@/store';
import { getInstance } from './index';
import { NavigationGuardNext, Route } from 'vue-router';

export const authGuard = (_to: Route, _from: Route, next: NavigationGuardNext) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const token = await authService.getTokenSilently({});
      store.commit('setToken', token);
      return next();
    }
    // Otherwise, log in
    authService.loginWithRedirect({});
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', async (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
};
