import { Module } from 'vuex';
import { RootState } from '..';
import * as accessApi from '@/services/access';
import { publiftApi } from '@/services/axios';
import { AxiosError } from 'axios';
import Vue from 'vue';
import { replaceArrayItems } from '@/utils/util';

export interface IGamNetwork {
  id: string;
  displayName: string;
  networkCode: string;
  propertyCode: string;
  timeZone: string;
  currencyCode: string;
  secondaryCurrencyCodes: string[];
  effectiveRootAdUnitId: string;
  isTest: boolean;
  childPublishers?: {
    approvedDelegationType: string;
    status: string;
    childNetworkCode: string;
  }[];
}

export interface Network extends accessApi.IADMNetwork {
  searchString?: string;
  network: string;
}

export interface ICreateLinkAdmAccount {
  account: string;
  admNetworkId: string;
}
export interface DfpNetworkState {
  googleNetworks: Network[];
  gamNetworkInfoMap: Record<string, IGamNetwork>;
  thirdPartyNetworks: Network[];
  allNetworks: Network[];
  loading: boolean;
  loadingGamNetworkInfo: Record<string, boolean>;
  errorMessage: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  networkSettings: {};
}

export const dfpNetworks: Module<DfpNetworkState, RootState> = {
  namespaced: true,
  state: {
    googleNetworks: [],
    gamNetworkInfoMap: {},
    thirdPartyNetworks: [],
    allNetworks: [],
    loading: false,
    loadingGamNetworkInfo: {},
    errorMessage: '',
    showSuccessMessage: false,
    showErrorMessage: false,
    networkSettings: {},
  },
  getters: {
    googleNetworks: (state) => state.googleNetworks,
    gamNetworkInfoMap: (state) => state.gamNetworkInfoMap,
    loadingGamNetworkInfo: (state) => state.loadingGamNetworkInfo,
    loading: (state) => state.loading,
    errorMessage: (state) => state.errorMessage,
    thirdPartyNetworks: (state) => state.thirdPartyNetworks,
    allNetworks: (state) => state.googleNetworks.concat(...state.thirdPartyNetworks),
    networkSettings: (state) => state.networkSettings,
  },
  mutations: {
    setGoogleNetworks: (state, payload: Network[]) => {
      replaceArrayItems(state.googleNetworks, payload);
    },
    setGamNetworkInfoMap: (state, payload: { id: string; result: IGamNetwork }) => {
      Vue.set(state.gamNetworkInfoMap, payload.id, payload.result);
    },
    setGamNetworkInfoLoading: (state, payload: { id: string; result: boolean }) => {
      Vue.set(state.loadingGamNetworkInfo, payload.id, payload.result);
    },
    setNetworks: (state, payload: Network[]) => {
      replaceArrayItems(state.thirdPartyNetworks, payload);
    },
    setLoading: (state, payload: boolean) => {
      state.loading = payload;
    },
    setErrorMessage: (state, payload: string) => {
      state.errorMessage = payload;
    },
    showSuccessMessage: (state, payload: boolean) => {
      state.showSuccessMessage = payload;
    },
    showErrorMessage: (state, payload: boolean) => {
      state.showErrorMessage = payload;
    },
  },
  actions: {
    loadGoogleNetworks: async ({ commit, state }, forceRefresh = false) => {
      if (state.googleNetworks.length === 0 || forceRefresh) {
        const { data: result } = await accessApi.getSavedADMNetworks();
        const admNetworks = result.map((item) => {
          const mod: accessApi.IADMNetwork & { searchString: string } = {
            ...item,
            searchString: `${item.networkCode} - ${item.displayName}`,
          };
          return mod;
        });
        commit('setGoogleNetworks', admNetworks);
      }
    },

    loadGamNetworkInfo: async ({ commit, state }, payload: { id: string; tenant: string }) => {
      const { id, tenant } = payload;
      const gamNetworkInfo: IGamNetwork | undefined = state.gamNetworkInfoMap[id];
      if (!gamNetworkInfo && !state.loadingGamNetworkInfo[id]) {
        commit('setGamNetworkInfoLoading', { id: payload.id, result: true });
        try {
          const request = await publiftApi.get(`/webapi/tenants/${tenant}/gam/${id}/networkInfo`);
          commit('setGamNetworkInfoMap', {
            id,
            result: request.data,
          });
          commit('setGamNetworkInfoLoading', { id: payload.id, result: false });
        } catch (e) {
          commit('setGamNetworkInfoLoading', { id: payload.id, result: false });
          throw e;
        }
      }
    },

    loadThirdPartyNetworks: async ({ commit, state }, forceRefresh = false) => {
      if (state.thirdPartyNetworks.length === 0 || forceRefresh) {
        const { data: result } = await publiftApi.get('/v2/access/thirdparty');
        const networks = result.map((network: Network) => {
          const formattedNetwork = {
            ...network,
            displayName: network.displayName,
          };
          if (!state.networkSettings[network.network]) {
            state.networkSettings[network.network] = [network];
          } else {
            state.networkSettings[network.network].push(network);
          }
          return formattedNetwork;
        });
        commit('setNetworks', networks);
      }
    },

    saveNetworkSettings: async ({ commit, dispatch }, payload) => {
      commit('setLoading', true);
      try {
        await publiftApi.post('/v2/access/thirdparty/save', payload);
        commit('showSuccessMessage', true);
        dispatch('loadThirdPartyNetworks');
        setTimeout(() => commit('showSuccessMessage', false), 5000);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.data) {
          commit('setErrorMessage', error.response.data);
          commit('showErrorMessage', true);
          setTimeout(() => commit('showErrorMessage', false), 5000);
        }
        commit('setLoading', false);
      }
      commit('setLoading', false);
    },
    setLoading: ({ commit }, payload: boolean) => {
      commit('setLoading', payload);
    },
    showErrorMessage: ({ commit }, payload: boolean) => {
      commit('showErrorMessage', payload);
    },
    loadAllNetworks: async ({ dispatch, commit }, forceRefresh = false) => {
      await Promise.all([
        commit('setLoading', true),
        dispatch('loadGoogleNetworks', forceRefresh),
        dispatch('loadThirdPartyNetworks', forceRefresh),
      ]);
      commit('setLoading', false);
    },
    getNetworSettingsByNetwork: ({ state }, payload: string) => {
      return state.thirdPartyNetworks.find((network) => network.network === payload);
    },
  },
};
