import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';

export interface ICommission {
  id: string;
  percent: number;
  networkShare?: number;
  networkTax?: number;
  associatedType: string;
  associatedId: string;
  displayName: string;
}

export interface ICommissionUpdate {
  id: string;
  percent?: number;
  networkShare?: number;
  networkTax?: number;
  appliedFromYear: number;
  appliedFromMonth: number;
}

export interface ICommissionState {
  commissions: ICommission[];
  errorMessage: string;
  successMessage: string;
}

export const commissions: Module<ICommissionState, RootState> = {
  namespaced: true,
  state: {
    commissions: [],
    errorMessage: '',
    successMessage: '',
  },
  getters: {
    commissions: (state) => state.commissions,
    errorMessage: (state) => state.errorMessage,
  },
  mutations: {
    setCommissionList: (state, payload: ICommission[]) => {
      state.commissions = payload;
    },
  },
  actions: {
    loadCommissions: async ({ commit, state }, payload = false) => {
      if (state.commissions.length === 0 || payload) {
        try {
          const { data: commissionList }: { data: ICommission[] } = await publiftApi.get('/v2/commissions/');
          commit('setCommissionList', commissionList);
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      return;
    },

    updateCommission: async ({}, payload: ICommissionUpdate) => {
      const { id } = payload;
      return await publiftApi.put(`/v2/commissions/${id}`, {
        ...payload,
      });
    },
  },
};
