import { Module } from 'vuex';
import {
  CmpProvider,
  CmpProviderOptions,
  FuseStatus,
  IJsonObject,
  IVideoSettings,
  TagRevision,
  IFuse,
  IFuseCmpSettings,
  IFuseAdUnitsDiff,
  IFuseBreakpointDiff,
  IFuseDiff,
  IFuseDiffNetworkData,
} from '@publift-libs/shared';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import store from '@/store';
import { AxiosError } from 'axios';

export { IFuseAdUnitsDiff, IFuseDiff };
export { IFuseCmpSettings, IFuse };

export interface IFuseNetworkSettings extends IFuseDiffNetworkData {
  isOn: boolean;
}

export interface IFusesState {
  accountCode?: string;
  fuses: IFuse[];
  loading: boolean;
  isUploading: boolean;
  uploadError?: string;
  selectedFuse?: IFuse;
  isDiffLoading: boolean;
  fuseDiff: IFuseDiff | null;
  diffError?: string;
}

export interface INewFuseSettings {
  name: string;
  breakpointOptions: {
    xl?: { enabled: boolean; timeout: number };
    l?: { enabled: boolean; timeout: number };
    m: { enabled: boolean; timeout: number };
    s: { enabled: boolean; timeout: number };
    xs?: { enabled: boolean; timeout: number };
  };
  onLoadCallback?: string;
  zoneCustomiser?: string;
  revision: TagRevision;
  tagSettings?: IJsonObject;
  networks?: IFuseNetworkSettings[];
  videoSettings?: IVideoSettings;
  enableAutoRegistration?: boolean;
  cmpProvider?: CmpProvider;
  cmpProviderOptions?: CmpProviderOptions;
}

const SIZE_LIST = ['XL', 'L', 'M', 'S', 'XS'];

const breakpointSorter = ({ size: size1 }: IFuseBreakpointDiff, { size: size2 }: IFuseBreakpointDiff): number => {
  const getSortValue = (sizeStr: string): number => SIZE_LIST.indexOf(sizeStr);

  return getSortValue(size1) < getSortValue(size2) ? 1 : -1;
};

export const fuses: Module<IFusesState, RootState> = {
  namespaced: true,
  state: {
    fuses: [],
    loading: false,
    isUploading: false,
    isDiffLoading: false,
    fuseDiff: null,
    accountCode: undefined,
    selectedFuse: undefined,
  },
  getters: {
    fuses: (state) => state.fuses.sort((f1, f2) => (f1.createdAt < f2.createdAt ? 1 : -1)),
    accountCode: (state) => state.accountCode,
    selectedFuse: (state) => state.selectedFuse,
    loading: (state) => state.loading,
    isUploading: (state) => state.isUploading,
    uploadError: (state) => state.uploadError,
    fuseDiff: (state) => state.fuseDiff,
    isDiffLoading: (state) => state.isDiffLoading,
    diffError: (state) => state.diffError,
    diffBreakpoints: (state) =>
      state.fuseDiff ? state.fuseDiff.breakpoints.sort((b1, b2) => breakpointSorter(b1, b2)) : [],
    diffAdUnits: (state) => (state.fuseDiff ? state.fuseDiff.adUnits : []),
    diffOnLoadCallback: (state) => (state.fuseDiff ? state.fuseDiff.onLoadCallback : undefined),
    diffZoneCustomiser: (state) => (state.fuseDiff ? state.fuseDiff.zoneCustomiser : undefined),
    diffRevision: ({ fuseDiff }) =>
      fuseDiff?.revision && fuseDiff.revision.main !== fuseDiff.revision.experiment ? fuseDiff.revision : null,
    diffFusegenVersion: ({ fuseDiff }) =>
      fuseDiff?.fusegenVersion && fuseDiff.fusegenVersion.main !== fuseDiff.fusegenVersion.experiment
        ? fuseDiff.fusegenVersion
        : null,
    diffTagSettings: ({ fuseDiff }) => fuseDiff?.tagSettings,
    diffVideoSettings: ({ fuseDiff }) => fuseDiff?.videoSettings,
    diffEnableAutoRegistration: ({ fuseDiff }) => fuseDiff?.enableAutoRegistration,
    diffCmp: ({ fuseDiff }) => fuseDiff?.cmp,
    diffNetworks: ({ fuseDiff }): { main: IFuseDiffNetworkData[]; experiment: IFuseDiffNetworkData[] } | undefined =>
      fuseDiff?.networks,
  },
  mutations: {
    setFuses: (state, payload: IFuse[]) => {
      state.fuses = payload;
    },
    setLoading: (state, payload: boolean) => {
      state.loading = payload;
    },
    setIsUploading: (state, payload: boolean) => {
      state.isUploading = payload;
    },
    setUploadError: (state, payload: string) => {
      state.uploadError = payload;
    },
    setAccountCode: (state, payload?: string) => {
      state.accountCode = payload;
    },
    selectFuse: (state, payload: IFuse) => {
      state.selectedFuse = payload;
    },
    setDiffLoading: (state, payload: boolean) => {
      state.isDiffLoading = payload;
    },
    setDiff: (state, payload: IFuseDiff | null) => {
      state.fuseDiff = payload;
    },
  },
  actions: {
    setAccountCode({ commit }, payload: string) {
      commit('setAccountCode', payload);
    },
    setUploadStarted({ commit }) {
      commit('setIsUploading', true);
      commit('setUploadError', undefined);
    },
    async setUploadSuccess({ dispatch, commit }) {
      commit('setIsUploading', false);
      commit('setUploadError', undefined);
      dispatch('getFusesFromAPI');
    },
    setUploadError({ commit }, payload: string) {
      commit('setIsUploading', false);
      commit('setUploadError', payload);
    },
    async getFusesFromAPI({ commit, state: { accountCode } }) {
      const tenantId = store.getters['tenants/tenantId'];

      if (!accountCode) {
        // TODO: Handle error here
        console.log('account code not set');
        return;
      }

      commit('setLoading', true);
      const { data }: { data: IFuse[] } = await publiftApi.get<IFuse[]>(
        `/webapi/tenants/${tenantId}/accounts/${accountCode}/fuse`
      );
      commit('setFuses', data);
      commit('setLoading', false);
      return;
    },
    async getFuseDiffFromAPI({ commit, state: { accountCode } }, fuseId: string) {
      const tenantId = store.getters['tenants/tenantId'];
      const url = `/webapi/tenants/${tenantId}/accounts/${accountCode}/fuse/${fuseId}/diff-from-main`;

      commit('setDiffLoading', true);

      // Fetch from API
      const { data } = await publiftApi.get<IFuseDiff>(url);
      commit('setDiff', data);

      commit('setDiffLoading', false);
    },
    clearFuseDiff({ commit }) {
      commit('setDiff', null);
    },
    selectFuse({ commit }, payload: IFuse) {
      commit('selectFuse', payload);
    },
    async activateFuse({ commit, dispatch, state: { fuses } }, payload: IFuse) {
      const tenantId = store.getters['tenants/tenantId'];

      const { accountCode, fuseId, revisionNumber, experimentPercent } = payload;
      try {
        const { data } = await publiftApi.patch(
          `/webapi/tenants/${tenantId}/accounts/${accountCode}/fuse/${fuseId}/activate/${revisionNumber}`,
          {
            experimentPercent,
          }
        );
        if (data === true) {
          const fuse = fuses.find((fuse) => {
            return fuse.fuseId === fuseId;
          });
          if (fuse) {
            fuses
              .filter((fuse) => fuse.status === FuseStatus.Activated)
              .map((fuse) => (fuse.status = FuseStatus.Generated));

            fuse.status = FuseStatus.Activated;
            commit('setFuses', fuses);
            dispatch('getFusesFromAPI');
            return true;
          }
        }
      } catch (error) {
        console.error(error);
        let message = 'Catch activate error';
        if (error instanceof AxiosError) {
          message =
            error.response?.status === 409 ? 'Incorrect revision. Reload the page and try again' : error.message;
        }
        return {
          message,
        };
      }
      return {
        message: 'Failed to activate',
      };
    },

    async deactivateFuse({ commit, dispatch, state }, payload: IFuse) {
      const tenantId = store.getters['tenants/tenantId'];
      const updatedFuses = state.fuses;
      const { accountCode, fuseId, revisionNumber } = payload;
      try {
        const { data } = await publiftApi.patch(
          `/webapi/tenants/${tenantId}/accounts/${accountCode}/fuse/${fuseId}/deactivate/${revisionNumber}`
        );
        if (data === true) {
          const updatedFuse = updatedFuses.find((fuse) => {
            return fuse.fuseId === fuseId;
          });
          if (updatedFuse) {
            updatedFuse.status = FuseStatus.Generated;
            commit('setFuses', updatedFuses);
            dispatch('getFusesFromAPI');
            return true;
          }
        }
      } catch (error) {
        console.error(error);
        let message = 'Catch deactivate error';
        if (error instanceof AxiosError) {
          message =
            error.response?.status === 409 ? 'Incorrect revision. Reload the page and try again' : error.message;
        }
        return {
          message,
        };
      }
      return {
        message: 'Failed to deactivate',
      };
    },
    async createFuse(
      { dispatch },
      { name, breakpointOptions: { xs, s, m, l, xl }, networks, ...otherSettings }: INewFuseSettings
    ) {
      const tenantId = store.getters['tenants/tenantId'];
      const accountCode = store.getters['fuses/accountCode'];
      const createPayload = {
        name,
        accountSettings: {
          breakpointOptions: {
            xs: xs?.enabled ? { headerBiddingTimeout: xs.timeout } : undefined,
            s: s?.enabled ? { headerBiddingTimeout: s.timeout } : undefined,
            m: m?.enabled ? { headerBiddingTimeout: m.timeout } : undefined,
            l: l?.enabled ? { headerBiddingTimeout: l.timeout } : undefined,
            xl: xl?.enabled ? { headerBiddingTimeout: xl.timeout } : undefined,
          },
          ...otherSettings,
        },
        networks: networks
          ?.filter(({ isOn }) => isOn)
          .map(({ networkId, allowServer, allowClient }) => ({
            networkId,
            allowClient,
            allowServer,
          })),
      };

      dispatch('setUploadStarted');
      try {
        await publiftApi.post(`/webapi/tenants/${tenantId}/accounts/${accountCode}/fuse`, createPayload);

        dispatch('setUploadSuccess');
      } catch (e) {
        console.error(e);
        dispatch('setUploadError', e instanceof AxiosError ? e.message : 'Create fuse failed');
      }
    },
  },
};
