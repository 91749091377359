<template>
  <v-row>
    <v-dialog v-model="isDialogOpen" :persistent="persistent" max-width="480">
      <custom-card :top-title="title" topElevation="5" topColor="dialog_top" actions-color="''">
        <v-card-text :style="`text-align: ${textPosition};`">{{ message }}</v-card-text>
        <template v-slot:actions>
          <v-btn
            class="mx-3 my-2"
            flat="flat"
            @click="noClicked"
            :aria-label="rejectActionAriaLabel"
            data-testid="ConfirmationNo"
            >No</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="mx-3 my-2" color="primary" @click="yesClicked" :aria-label="acceptActionAriaLabel">Yes</v-btn>
        </template>
      </custom-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import CustomCard from '@/components/ui/CustomCard.vue';

export default (Vue as VueConstructor<Vue>).extend({
  components: {
    CustomCard,
  },

  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    message: {
      type: String,
      default: '',
    },
    textPosition: {
      type: String,
      default: 'left',
    },
    rejectActionAriaLabel: {
      type: String,
      default: 'Reject Dialog Option',
    },
    acceptActionAriaLabel: {
      type: String,
      default: 'Accept Dialog Option',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    yesClicked() {
      this.$emit('on-click-yes');
    },
    noClicked() {
      this.$emit('on-click-no');
    },
  },
  computed: {
    isDialogOpen: {
      get(): boolean {
        return this.isOpen;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
});
</script>
