import store from '../store';
import { publiftApi } from '@/services/axios';
import {
  IManualReportingFile,
  IDeleteResult,
  IManualReportingAccountIdentifier,
  ICreateManualReportingAccountIdentifier,
  IFormattedManualReportingAccountIdentifier,
} from '@/store/modules/manual-reporting';

export const getManualUploadedFiles = async (): Promise<IManualReportingFile[]> => {
  const tenantCode = store.getters['tenants/tenantCode'];
  const { data } = await publiftApi.get<IManualReportingFile[]>(
    `/webapi/tenants/${tenantCode}/adnetwork-manual-report`
  );
  return data;
};

export const deleteFileAndRecords = async (filepath: string): Promise<IDeleteResult> => {
  const tenantCode = store.getters['tenants/tenantCode'];
  const { data } = await publiftApi.delete<IDeleteResult>(`/webapi/tenants/${tenantCode}/adnetwork-manual-report`, {
    params: {
      filepath,
    },
  });
  return data;
};

export const getManualReportAccountIdentifiers = async (): Promise<IFormattedManualReportingAccountIdentifier[]> => {
  const tenantCode = store.getters['tenants/tenantCode'];
  const { data } = await publiftApi.get<IFormattedManualReportingAccountIdentifier[]>(
    `/webapi/tenants/${tenantCode}/adnetwork-manual-report/account-identifier`
  );
  return data;
};
export const createManualReportAccountIdentifiers = async (
  input: ICreateManualReportingAccountIdentifier[]
): Promise<IManualReportingAccountIdentifier[]> => {
  const tenantCode = store.getters['tenants/tenantCode'];
  const { data } = await publiftApi.post<IManualReportingAccountIdentifier[]>(
    `/webapi/tenants/${tenantCode}/adnetwork-manual-report/account-identifier`,
    input
  );
  return data;
};
export const deleteManualReportAccountIdentifiers = async (
  input: IManualReportingAccountIdentifier[]
): Promise<IManualReportingAccountIdentifier[]> => {
  const tenantCode = store.getters['tenants/tenantCode'];
  const { data } = await publiftApi.delete<IManualReportingAccountIdentifier[]>(
    `/webapi/tenants/${tenantCode}/adnetwork-manual-report/account-identifier`,
    {
      data: input,
    }
  );
  return data;
};
