import { publiftApi } from '@/services/axios';
import { AxiosResponse } from 'axios';
import * as accessApi from '@/services/access';

interface BaseGoogleKey {
  id: string;
  type: 'oauth2_refresh' | 'jwt_key';
  data: {};
  createdBy: string;
  clients?: string[];
  tenantOwned: boolean;
}

export interface GoogleOAuth2Key extends BaseGoogleKey {
  type: 'oauth2_refresh';
  data: {
    credentials: {
      email: string;
      refreshToken: string;
    };
    clientId: string;
  };
}

export interface GoogleJWTKey extends BaseGoogleKey {
  type: 'jwt_key';
  data: {
    jwtOpts: JWTOptions;
  };
}

type Res<T> = Promise<AxiosResponse<T>>;

export interface INetwork extends accessApi.IAdManagerNetwork {
  active?: boolean;
  searchString?: string;
}
export interface IAdManagerNetwork {
  id: string;
  displayName: string;
  networkCode: string;
  propertyCode: string;
  timeZone: string;
  currencyCode: string;
  secondaryCurrencyCodes: string[];
  effectiveRootAdUnitId: string;
  isTest: boolean;
}

export interface JWTOptions {
  email?: string;
  keyFile?: string;
  key?: string;
  keyId?: string;
  scopes?: string | string[];
  subject?: string;
  additionalClaims?: {};
}

export interface ICreateADMNetwork {
  networkCode?: string;
  commission: number;
  displayName: string;
  authId: string;
  clients?: string[];
  tenantOwned: boolean;
  id: string;
  isMcm?: boolean;
}

export interface IADMNetwork extends ICreateADMNetwork {
  createdBy: string;
}

export type GoogleKey = GoogleJWTKey | GoogleOAuth2Key;

export const getADMNetworks = (): Res<IAdManagerNetwork[]> => publiftApi.get('/v2/access/gam/networksfromkey');

export const getSavedADMNetworks = (): Res<IADMNetwork[]> => publiftApi.get('/v2/access/gam/networks');

export const saveADMNetwork = (input: ICreateADMNetwork): Res<string> =>
  publiftApi.post('/v2/access/gam/save/network', input);
