import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import store from '@/store';

export interface IAB {
  [key: string]: string;
}

export interface IabState {
  miscIab?: IAB;
}

export const miscIab: Module<IabState, RootState> = {
  namespaced: true,
  state: {
    miscIab: {},
  },
  getters: {
    miscIab: (state) => state.miscIab,
  },
  mutations: {
    setIab: (state, payload: IAB) => {
      // eslint-disable-next-line no-param-reassign
      state.miscIab = payload;
    },
  },
  actions: {
    getMiscIabFromAPI: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data: allIab }: { data: IAB[] } = await publiftApi.get(`/webapi/tenants/${tenantId}/misc/iab.json`);
      commit('setIab', allIab);
      return;
    },
  },
};
