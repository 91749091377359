import 'reflect-metadata';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import Router from 'vue-router';
import { getRouter } from './router';
import store from './store/index';
import VueGtag from 'vue-gtag';
import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import { fetchTenantConfig, TenantConfig } from '@/services/tenant';
import { Auth0Plugin } from './auth';
import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

Vue.config.productionTip = false;

/**
 * This is a global event bus that all components can either listen or emit to.
 * When using this throughout the application you will need to pre-fix the line with `@ts-ignore`,
 * this is due to how single file components in vue + typescript work.
 * See the following for more information:
 * - https://vuejs.org/v2/guide/typescript.html#Augmenting-Types-for-Use-with-Plugins
 * - https://github.com/vuejs/vue/issues/5298#issuecomment-405197687
 */
Vue.prototype.$EVENT_BUS = new Vue();

const startApp = (tenantConfig: TenantConfig) => {
  const { dashboardConfig } = tenantConfig;

  // Initialise Analytics
  const analytics = Analytics({
    plugins: [
      mixpanelPlugin({
        token: dashboardConfig.mixpanelToken,
        enabled: Boolean(dashboardConfig.mixpanelToken),
      }),
    ],
  });

  Vue.use(Router);

  const router = getRouter(analytics, dashboardConfig);

  Vue.use(
    VueGtag,
    {
      config: { id: dashboardConfig.googleAnalyticsTrackingId },
    },
    router
  );

  const auth0Options: Auth0ClientOptions = {
    domain: tenantConfig.dashboardConfig.auth0Domain,
    clientId: tenantConfig.dashboardConfig.auth0ClientId,
    authorizationParams: {
      audience: tenantConfig.dashboardConfig.auth0ApiIdentifier,
      organization: tenantConfig.organizationId,
      redirect_uri: `${window.location.origin}/login`,
    },
  };

  Vue.use(Auth0Plugin, {
    ...auth0Options,
    onRedirectCallback: (appState) => {
      console.debug(`onRedirectCallback fired: ${JSON.stringify(appState)}`);
      router.push(appState?.targetUrl ? appState.targetUrl : window.location.pathname);
    },
  });

  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
    provide: {
      analytics,
      dashboardConfig,
      tenantConfig,
      routes: router.options.routes,
    },
  }).$mount('#app');
};

fetchTenantConfig(window.location.hostname)
  .then((config) => {
    startApp(config);
  })
  .catch((err) => {
    console.error('failed to fetch tenant config', err);
  });
