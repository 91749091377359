import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import { IProgress, ITag } from '@/services/tags';
import store from '../../store';
import { AkamaiStatus, IAccount, IAkamaiAccount, TagSettingsDiffDTO } from '@publift-libs/shared';
import { VisitResultDTO } from '@publift-libs/sitecheck';
import { filterTagDiff } from '@/utils/tag.utils';

export interface Tag {
  id?: number;
  time: number;
  accounts: IAccount[];
  fuseid?: number;
  fuseVersion?: number;
  latestProgress?: IProgress;
  yandexBlockId?: string;
}

export interface TagState {
  tags?: ITag[];
  isDiffLoading: boolean;
  tagSettingsDiff: TagSettingsDiffDTO | null;
}

export const tags: Module<TagState, RootState> = {
  namespaced: true,
  state: {
    tags: [],
    isDiffLoading: false,
    tagSettingsDiff: null,
  },
  getters: {
    getTags: (state) => state.tags,
    getTagsByAccountCodes: (state) => (filteredAccounts: string[]) =>
      state.tags!.filter((tag) => tag.accounts.find((a) => filteredAccounts.includes(a.code))),
    isDiffLoading: (state) => state.isDiffLoading,
    tagSettingsDiff: (state) => state.tagSettingsDiff,
    filteredDiff: (state, { tagSettingsDiff }) =>
      tagSettingsDiff?.publishedSettings ? filterTagDiff(tagSettingsDiff) : null,
    tagSettingsDiffSize: (state, { filteredDiff }) => (filteredDiff ? JSON.stringify(filteredDiff).length : 0),
  },
  mutations: {
    setTags: (state, payload: ITag[]) => {
      // eslint-disable-next-line no-param-reassign
      state.tags = payload;
    },
    setDiffLoading: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.isDiffLoading = payload;
    },
    setDiff: (state, payload: TagSettingsDiffDTO | null) => {
      // eslint-disable-next-line no-param-reassign
      state.tagSettingsDiff = payload;
    },
  },
  actions: {
    getTagsFromAPI: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];
      const [{ data: allTags }, { data: akamaiAccounts }, { data: allPublishedTagDeployments }] = await Promise.all([
        publiftApi.get<Tag[]>(`/webapi/tenants/${tenantId}/tags`),
        publiftApi.get<IAkamaiAccount[]>(`/webapi/tenants/${tenantId}/account-experiment/accounts`),
        publiftApi.get(`/webapi/tenants/${tenantId}/tags/deployments/published`),
      ]);
      allTags.forEach((tag: any) => {
        if (tag.breakpoints?.xs?.minimum_width > 0) {
          console.warn(
            `WARNING: value of breakpoint-XS is not 0 [current value=${tag.breakpoints?.xs?.minimum_width}, accountCode=${tag.accounts[0]?.code}]`
          );
        }
      });
      const tagsWithAkamaiStatus = allTags.map((tag) => {
        const status = akamaiAccounts.find((akamaiAccount) => tag.accounts[0]?.code === akamaiAccount.accountCode);
        return {
          ...tag,
          abStatus: status ? status.status : undefined,
          abPercent: status ? status.experimentPercent : 0,
        };
      });

      const tagEvents: Tag[] = tagsWithAkamaiStatus.map((tag) => {
        const deployment = allPublishedTagDeployments.find((i) => i.tagId === tag.id);
        return {
          ...tag,
          fuseGenerationUUID: deployment?.fuseGenerationUUID,
          publishedAt: deployment?.publishedAt,
          publishedBy: deployment?.publishedBy,
          publicationId: deployment?.publicationId,
          isRollback: Boolean(deployment?.isRollback),
        };
      });
      const mappedWithAccounts = tagEvents.map((tag) => {
        const account = tag.accounts[0];
        return {
          ...tag,
          yandexEnabled: !!tag.yandexBlockId,
          account,
        };
      });
      commit('setTags', mappedWithAccounts);
      return;
    },
    regenerateTag: async ({}, id: number) => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.post(`/webapi/tenants/${tenantId}/tags/${id}/regenerate`);
    },
    previewTag: async (_, accountCode: string) => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.post(`/webapi/tenants/${tenantId}/accounts/${accountCode}/tags/preview-main`);
    },
    createTag: async (_, data: ITag) => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.post(`/webapi/tenants/${tenantId}/tags`, data);
    },
    updateTag: async (_, data: ITag) => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.put(`/webapi/tenants/${tenantId}/tags`, data);
    },
    regenerateRequest: async (_, id: string): Promise<string> => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.post(`/webapi/tenants/${tenantId}/tags/${id}/regenerate-request`);
    },
    initAkamaiAccount: async (_, accountCode: string): Promise<IAkamaiAccount | undefined> => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data } = await publiftApi.patch(`/webapi/tenants/${tenantId}/akamai/${accountCode}/init`);
      return data;
    },
    statusAkamaiAccount: async (_, accountCode: string): Promise<IAkamaiAccount | undefined> => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data } = await publiftApi.patch(`/webapi/tenants/${tenantId}/akamai/${accountCode}/status`);
      return data;
    },
    akamaiStatus: async (_): Promise<AkamaiStatus> => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data } = await publiftApi.get(`/webapi/tenants/${tenantId}/akamai/status`);
      return data;
    },
    akamaiPendingAccounts: async (_): Promise<IAkamaiAccount[]> => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data } = await publiftApi.patch(`/webapi/tenants/${tenantId}/account-experiment/accounts/pending`);
      return data;
    },
    akamaiActivateLatestVersion: async (_): Promise<AkamaiStatus> => {
      const tenantId = store.getters['tenants/tenantId'];
      const { data } = await publiftApi.patch(`/webapi/tenants/${tenantId}/akamai/activate`);
      return data;
    },
    previewAll: async () => {
      const tenantId = store.getters['tenants/tenantId'];

      return publiftApi.post<{ timestamp: number }>(`/webapi/tenants/${tenantId}/tags/preview-all`, {});
    },
    pageCheck: async (
      _,
      { accountCode, unpublishedSiteCheck }: { accountCode: string; unpublishedSiteCheck: boolean }
    ): Promise<VisitResultDTO[]> => {
      const tenantId = store.getters['tenants/tenantId'];
      const url = `/public-api/sitecheck/account/${tenantId}/${accountCode}/JP${
        unpublishedSiteCheck ? '/unpublishedSiteCheck' : ''
      }`;

      const result = await publiftApi.get(url, {
        validateStatus: (status) => status == 200,
        responseType: 'json',
      });

      if (!result.data) {
        throw new Error(`Unexpected response: ${JSON.stringify(result.data)}`);
      }

      return result.data;
    },
    pageCheckByUrl: async (
      _,
      { accountCode, url, tagId }: { accountCode: string; url: string; tagId: number }
    ): Promise<VisitResultDTO[]> => {
      const tenantId = store.getters['tenants/tenantId'];
      const sitecheckUrl = `/public-api/sitecheck/account/${tenantId}/${accountCode}/${tagId}/page/${url}`;

      const result = await publiftApi.get(sitecheckUrl, {
        validateStatus: (status) => status == 200,
        responseType: 'json',
      });

      if (!result.data) {
        throw new Error(`Unexpected response: ${JSON.stringify(result.data)}`);
      }

      return result.data;
    },
    unpublishedPageCheckByUrl: async (
      _,
      { accountCode, url, tagId }: { accountCode: string; url: string; tagId: number }
    ): Promise<VisitResultDTO[]> => {
      const tenantId = store.getters['tenants/tenantId'];
      const sitecheckUrl = `/public-api/sitecheck/account/${tenantId}/${accountCode}/${tagId}/page/${url}/unpublishedSiteCheck`;

      const result = await publiftApi.get(sitecheckUrl, {
        validateStatus: (status) => status == 200,
        responseType: 'json',
      });

      if (!result.data) {
        throw new Error(`Unexpected response: ${JSON.stringify(result.data)}`);
      }

      return result.data;
    },
    getTagSettingsDiff: async ({ commit }, tagId: number): Promise<void> => {
      const tenantId = store.getters['tenants/tenantId'];

      commit('setDiffLoading', true);
      const result = await publiftApi.get(`/webapi/tenants/${tenantId}/tags/${tagId}/diff-from-published`, {
        validateStatus: (status) => status == 200,
        responseType: 'json',
      });

      commit('setDiff', result.data);
      commit('setDiffLoading', false);
    },
    clearSettingsDiff: ({ commit }): void => {
      commit('setDiff', null);
    },
  },
};
