import { getInstance } from '@/auth';
import axios, { AxiosResponse, AxiosError, AxiosRequestHeaders } from 'axios';

interface AxiosInterceptorsStore {
  [key: string]: number;
}

const interceptors: AxiosInterceptorsStore = {};

export enum STATUS_CODES {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  UNAVAILABLE = 503,
}

/**
 * @paramsSerializer removes the square brackets from api requests
 */
export const publiftApi = axios.create({
  paramsSerializer: {
    serialize: (params: Record<string, any>) => {
      const res = Object.entries(params)
        .reduce((prev, [k, v]) => {
          if (Array.isArray(v)) {
            v.forEach((value) => {
              prev.push(`${k}=${encodeURIComponent(String(value))}`);
            });
          } else {
            prev.push(`${k}=${encodeURIComponent(String(v))}`);
          }
          return prev;
        }, [] as string[])
        .join('&');
      return res;
    },
  },
});

publiftApi.interceptors.request.use(async (config) => {
  let updatedConfig = config;
  const authService = getInstance();

  if (!config.headers?.Authorization) {
    if (authService.isAuthenticated) {
      const token = `Bearer ${await authService.getTokenSilently({})}`;

      updatedConfig = {
        ...config,
        headers: {
          ...(config.headers || {}),
          Authorization: token,
        } as AxiosRequestHeaders,
      };

      publiftApi.defaults.headers.common.Authorization = token;
    }
  }

  return updatedConfig;
}, Promise.reject);

export function addResponseInterceptor(
  check: (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  onrejected?: (error: AxiosError) => any,
  interceptorName?: string
): void {
  const interceptor = publiftApi.interceptors.response.use(check, onrejected);
  if (interceptorName) {
    interceptors[interceptorName] = interceptor;
  }
}

export function unsetAuthToken(): void {
  delete publiftApi.defaults.headers.common.Authorization;
}
