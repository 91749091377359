import { isArray, isEqual } from 'lodash';

const filterArray = (
  publishedData: any[],
  currentData: any[],
  key: string
): { filteredPublishedArray: any[]; filteredCurrentArray: any[] } => {
  const keyToIndexKeyMap: { [key: string]: (any) => string } = {
    adUnits: (adUnit) => adUnit.id,
    networkAdUnits: (networkAdUnit) => `${networkAdUnit.adUnitId}-${networkAdUnit.network}`,
    networks: (network) => network.code,
    zones: (zone) => zone.id,
    gamAdUnits: (gamAdUnit) => gamAdUnit.id,
  };
  const sortKeyFunc = keyToIndexKeyMap[key];

  if (!sortKeyFunc) {
    // Don't know about this array type - just return everything
    return { filteredPublishedArray: publishedData, filteredCurrentArray: currentData };
  }

  // Map all data via sortKey
  const initialMap = currentData.reduce((acc, currentDataItem) => {
    const sortKeyId = sortKeyFunc(currentDataItem);
    return {
      ...acc,
      [sortKeyId]: { currentDataItem },
    };
  }, {});

  const doubleMap = publishedData.reduce((acc, publishedDataItem) => {
    const sortKeyId = sortKeyFunc(publishedDataItem);

    return {
      ...acc,
      [sortKeyId]: {
        currentDataItem: acc[sortKeyId]?.currentDataItem,
        publishedDataItem,
      },
    };
  }, initialMap);

  // Iterate over keys and return any not equal or unique
  return Object.keys(doubleMap).reduce(
    (acc, objectKey) => {
      const dataItems = doubleMap[objectKey];

      if (isEqual(dataItems.currentDataItem, dataItems.publishedDataItem)) {
        // Ignore
        return acc;
      }

      return {
        filteredPublishedArray: dataItems.publishedDataItem
          ? [...acc.filteredPublishedArray, dataItems.publishedDataItem]
          : acc.filteredPublishedArray,
        filteredCurrentArray: dataItems.currentDataItem
          ? [...acc.filteredCurrentArray, dataItems.currentDataItem]
          : acc.filteredCurrentArray,
      };
    },
    { filteredPublishedArray: [], filteredCurrentArray: [] } as {
      filteredPublishedArray: any[];
      filteredCurrentArray: any[];
    }
  );
};

const filterAccount = (
  publishedAccount: any,
  currentAccount
): { filteredPublishedAccount: any; filteredCurrentAccount: any } => {
  if (!isArray(publishedAccount.networks) || !isArray(currentAccount.networks)) {
    return { filteredPublishedAccount: publishedAccount, filteredCurrentAccount: currentAccount };
  }

  const initialMap = currentAccount.networks.reduce((acc, network) => {
    return {
      ...acc,
      [network.networkId]: { currentNetwork: network },
    };
  }, {});

  const doubleMap = publishedAccount.networks.reduce((acc, network) => {
    return {
      ...acc,
      [network.networkId]: {
        currentNetwork: acc[network.networkId]?.currentNetwork,
        publishedNetwork: network,
      },
    };
  }, initialMap);

  // Put anything new or removed at end
  const { commonNetworkIds, newNetworkIds, removedNetworkIds } = Object.keys(doubleMap).reduce(
    (acc, key) => {
      const networkData = doubleMap[key];

      if (networkData.currentNetwork && networkData.publishedNetwork) {
        return {
          ...acc,
          commonNetworkIds: [...acc.commonNetworkIds, key],
        };
      }

      if (networkData.currentNetwork) {
        return {
          ...acc,
          newNetworkIds: [...acc.newNetworkIds, key],
        };
      }

      return {
        ...acc,
        removedNetworkIds: [...acc.removedNetworkIds, key],
      };
    },
    { commonNetworkIds: [], newNetworkIds: [], removedNetworkIds: [] } as {
      commonNetworkIds: string[];
      newNetworkIds: string[];
      removedNetworkIds: string[];
    }
  );

  const filteredPublishedAccount = {
    ...publishedAccount,
    networks: [
      ...commonNetworkIds.map((networkId) => doubleMap[networkId].publishedNetwork),
      ...removedNetworkIds.map((networkId) => doubleMap[networkId].publishedNetwork),
    ],
  };

  const filteredCurrentAccount = {
    ...currentAccount,
    networks: [
      ...commonNetworkIds.map((networkId) => doubleMap[networkId].currentNetwork),
      ...newNetworkIds.map((networkId) => doubleMap[networkId].currentNetwork),
    ],
  };

  return { filteredPublishedAccount, filteredCurrentAccount };
};

export const filterTagDiff = ({
  publishedSettings,
  currentSettings,
}: {
  publishedSettings: any;
  currentSettings: any;
}): { publishedSettings: any; currentSettings: any } => {
  return Object.keys(currentSettings).reduce(
    (acc, key) => {
      const publishedData = publishedSettings[key];
      const currentData = currentSettings[key];

      if (isEqual(publishedData, currentData)) {
        // Don't show in diff
        return acc;
      }

      if (isArray(publishedData)) {
        const { filteredPublishedArray, filteredCurrentArray } = filterArray(publishedData, currentData, key);

        return {
          publishedSettings: {
            ...acc.publishedSettings,
            [key]: filteredPublishedArray,
          },
          currentSettings: {
            ...acc.currentSettings,
            [key]: filteredCurrentArray,
          },
        };
      }

      if (key === 'account') {
        const { filteredPublishedAccount, filteredCurrentAccount } = filterAccount(publishedData, currentData);

        return {
          publishedSettings: {
            ...acc.publishedSettings,
            [key]: filteredPublishedAccount,
          },
          currentSettings: {
            ...acc.currentSettings,
            [key]: filteredCurrentAccount,
          },
        };
      }

      return {
        publishedSettings: {
          ...acc.publishedSettings,
          [key]: publishedData,
        },
        currentSettings: {
          ...acc.currentSettings,
          [key]: currentData,
        },
      };
    },
    { publishedSettings: {}, currentSettings: {} }
  );
};
