<template>
  <v-card class="rounded-lg">
    <section class="top">
      <offset-helper :offset="topOffset" :top-relative="topRelative">
        <v-card class="rounded-lg py-3 px-4" :color="topColor" :class="`elevation-${topElevation}`" :dark="topDark">
          <v-row :class="topCentered ? 'align-center' : ''" :style="`height: ${topHeight}`">
            <div :class="topTitleTypography" class="font-weight-regular text-center flex py-2">
              {{ topTitle }}
            </div>
          </v-row>
          <div class="category font-weight-light text-center mt-3" v-if="topSubtitle">{{ topSubtitle }}</div>
        </v-card>
      </offset-helper>
    </section>
    <!-- Removing this allows you to scroll the dialog box  -->
    <!-- <section class="content">   -->
    <v-card-text class="content pa-6 overflow-y-auto">
      <!-- @slot Anything inserted inside here will be rendered in the middle -->
      <slot />
    </v-card-text>
    <!-- </section> -->
    <section class="actions">
      <div v-if="$slots.actions">
        <v-card-actions :class="actionsColor">
          <v-row :class="`justify-${actionsAlignment}`" class="pa-2">
            <!-- @slot Anything inserted inside here will be rendered down the bottom, and aligned right. -->
            <slot name="actions" />
          </v-row>
        </v-card-actions>
      </div>
    </section>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import OffsetHelper from '@/components/helpers/Offset.vue';
/**
 * The Custom Card component is a wrapper over the v-card from vuetify.
 * This is the standardised card that pretty much all view components/dialogs/pages should use for the main content.
 */
export default Vue.extend({
  name: 'CustomCard',
  components: {
    OffsetHelper,
  },
  props: {
    // Top Title Props
    /**
     * The title that will appear at the top of the component
     */
    topTitle: {
      type: String,
      default: 'Insert Title Here',
    },
    /**
     * A vuetify font-size typography setting. <br/>
     * See: https://vuetifyjs.com/en/style/typography#font-sizes for more information.
     */
    topTitleTypography: {
      type: String,
      default: 'title',
    },
    /**
     * Whether or not the vuetify dark property should be applied to the top offset component.
     */
    topDark: {
      type: Boolean,
      default: true,
    },
    /**
     * The subtitle that will appear at the top of the component, this is optional.
     */
    topSubtitle: {
      type: String,
    },
    /**
     * Whether or not the title should be centred
     */
    topCentered: {
      type: Boolean,
      default: true,
    },
    /**
     * The offset of the top of the component, reduce this to 0 for no floating offset.
     */
    topOffset: {
      type: [Number, String],
      default: -20,
    },
    topRelative: {
      type: [Number, String],
      default: -20,
    },

    /**
     * The colour of the top offset.
     */
    topColor: {
      type: String,
      default: 'card_top',
    },
    /**
     * The elevation/shadow of the title.
     */
    topElevation: {
      type: [Number, String],
      default: 0,
    },
    /**
     * The height of the top bar which contains the title.
     */
    topHeight: {
      type: [Number, String],
      default: 'unset',
    },
    // Main Slot Props
    /**
     * The spacing between the main content and the top bar.
     */
    contentTopMargin: {
      type: [Number, String],
      default: 10,
    },
    // Actions Props
    /**
     * The background color for the entire actions bar.
     */
    actionsColor: {
      type: String,
      default: '',
    },
    /**
     * Where the actions should be aligned, options are: start, center, end.
     */
    actionsAlignment: {
      type: String,
      default: 'end',
      validator: (val: string) => ['start', 'center', 'end'].includes(val),
    },
  },
});
</script>

<style></style>
