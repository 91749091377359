import { Module } from 'vuex';
import { RootState } from '@/store';
import { getManualReportAccountIdentifiers, getManualUploadedFiles } from '@/services/manual-reporting';

export interface IManualReportingFile {
  filename: string;
  filePath: string;
  network: string;
  timestamp: number;
}

export interface ICreateManualReportingAccountIdentifier {
  accountCode: string;
  networkCode: string;
  identifier: string;
}

export interface IManualReportingAccountIdentifier {
  tenantId: string;
  tenantCode: string;
  accountCode: string;
  networkCode: string;
  identifier: string;
}

export interface IFormattedManualReportingAccountIdentifier {
  tenantId: string;
  tenantCode: string;
  clientCode: string;
  accountCode: string;
  networkCode: string;
  identifier: string;
}

export interface ManualReportingState {
  loadingUserFiles: boolean;
  loadingAccountIdentifiers: boolean;
  currentlyUploadingFile: boolean;
  userManualReportingFiles: IManualReportingFile[];
  manualReportingAccountIdentifiers: IFormattedManualReportingAccountIdentifier[];
}

export interface IUploadResult {
  status: UploadResultStatus;
  errors: string[];
}

export interface IDeleteResult {
  status: UploadResultStatus;
}

export enum UploadResultStatus {
  IMPORTED = 'IMPORTED',
  FAILED_IMPORT = 'FAILED_IMPORT',
  DELETE_OK = 'DELETE_OK',
}

export const manualReporting: Module<ManualReportingState, RootState> = {
  namespaced: true,
  state: {
    loadingUserFiles: false,
    loadingAccountIdentifiers: false,
    currentlyUploadingFile: false,
    userManualReportingFiles: [],
    manualReportingAccountIdentifiers: [],
  },
  getters: {
    loadingUserFiles: (state) => state.loadingUserFiles,
    loadingAccountIdentifiers: (state) => state.loadingAccountIdentifiers,
    currentlyUploadingFile: (state) => state.currentlyUploadingFile,
    userManualReportingFiles: (state) => state.userManualReportingFiles,
    manualReportingAccountIdentifiers: (state) => state.manualReportingAccountIdentifiers,
  },
  mutations: {
    // File management
    setLoadingUserFiles: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingUserFiles = payload;
    },
    setUploadedManualReportingFiles: (state, payload: IManualReportingFile[]) => {
      // eslint-disable-next-line no-param-reassign
      state.userManualReportingFiles = payload;
    },
    setLoadingAccountIdentifiers: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingAccountIdentifiers = payload;
    },
    setCurrentlyUploadingFile: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.currentlyUploadingFile = payload;
    },
    // Account identifier management
    setManualReportingAccountIdentifiers: (state, payload: IFormattedManualReportingAccountIdentifier[]) => {
      // eslint-disable-next-line no-param-reassign
      state.manualReportingAccountIdentifiers.length = 0;
      state.manualReportingAccountIdentifiers.push(...payload);
    },
  },
  actions: {
    async loadUploadedManualReportingFiles({ commit }) {
      commit('setUploadedManualReportingFiles', await getManualUploadedFiles());
    },
    setLoadingUserFiles({ commit }, payload: boolean) {
      commit('setLoadingUserFiles', payload);
    },
    setLoadingAccountIdentifiers({ commit }, payload: boolean) {
      commit('setLoadingAccountIdentifiers', payload);
    },
    setCurrentlyUploadingFile({ commit }, payload: boolean) {
      commit('setCurrentlyUploadingFile', payload);
    },
    async getManualReportAccountIdentifiers({ commit }) {
      commit('setManualReportingAccountIdentifiers', await getManualReportAccountIdentifiers());
    },
  },
};
