/**
 * Quick clone with JSON parse and stringify of an object
 * @param input Object to input
 */
export function clone<T extends object>(input: T): T {
  return JSON.parse(JSON.stringify(input));
}

/**
 * Applies default object parameters to an object
 * @param input Input that will have defaults applied to
 * @param def Object with default parameters
 */
export function applyDefault<T extends object>(input: T, def: T): T {
  const applied = {};

  Object.assign(applied, def, input);

  return clone(applied) as T;
}

/**
 * Returns a promise that fires after a number of milliseconds
 * @param ms Amount of milliseconds to wait
 */
export function wait(ms = 1000): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

/**
 * Gets a value from an object based on the path.
 * If the value does not exist, return undefined or default.
 * @param object Object to navigate to value
 * @param path Path of value
 * @param def Default to return if there is no value
 */
export function get<T extends object, U>(object: T, path: string, def?: U): U | undefined {
  if (!path) return def;

  const pathArray = path.split('.');

  if (!pathArray.length) return def;

  const checkPath = (val: {}, index = 0) => {
    if (val[pathArray[index]]) {
      if (index !== pathArray.length - 1) {
        if (typeof val === 'object') {
          return checkPath(val[pathArray[index]], index + 1);
        } else {
          return def;
        }
      }
      return val[pathArray[index]];
    }
    return def;
  };
  return checkPath(object);
}

/**
 * Compares two objects for deep equal. Works with primitive and plain object values
 * @param a Object to test
 * @param b Object to test
 */
export function isObjectEqual<T>(a: T, b: T): boolean {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
    return false; // Handle null and non-objects
  }

  if (typeof a === 'object' && typeof b === 'object') {
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();

    if (aKeys.length !== bKeys.length) return false;

    if (!aKeys.every((key) => b[key])) return false;

    return aKeys.every((key) => isObjectEqual(a[key], b[key]));
  }

  return false;
}

export function replaceFavicon(id: string, href: string): void {
  if (!document || !document.head) return;
  const oldIcon = document.getElementById(id) as HTMLLinkElement;
  const newIcon = document.createElement('link');
  newIcon.id = id;
  newIcon.rel = 'shortcut icon';
  newIcon.href = href;
  if (oldIcon) {
    document.head.removeChild(oldIcon);
  }
  document.head.appendChild(newIcon);
}
