import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import { replaceFavicon } from '@/services/util';
import { saveAs } from 'file-saver';
import { TenantState, Theme, LoginConfig, SidebarConfig, TenantConfig, ToolbarConfig } from '@/services/tenant';

export const tenants: Module<TenantState, RootState> = {
  namespaced: true,
  state: {
    features: {},
    loadingTheme: false,
    themeLoaded: false,
    apiUrl: '',
    cdnBaseUrl: '',
    tagName: '',
    tenantId: undefined,
    code: '',
  },

  getters: {
    features: (state) => state.features,
    loadingTheme: (state) => state.loadingTheme,
    themeLoaded: (state) => state.themeLoaded,
    apiUrl: (state) => state.apiUrl,
    cdnBaseUrl: (state) => state.cdnBaseUrl,
    tagName: (state) => state.tagName,

    tenantCode: (state) => state.code,
    tenantId: (state) => state.tenantId,

    getTheme: (state) => state.theme,
    loginConfig: (state) => state.login,
    toolbarConfig: (state) => state.toolbar,
    sidebarConfig: (state) => state.sidebar,
    isLegacyTenant: (state) => Boolean(state.features.legacyAkamaiName),
    isExperimentsEnabled: (state) => Boolean(state.features.experiments),
    isJiraEnabled: (state) => Boolean(state.features.useJira),
  },

  mutations: {
    setLoadingTheme: (state, payload: boolean) => {
      state.loadingTheme = payload;
    },
    setThemeLoaded: (state, payload: boolean) => {
      state.themeLoaded = payload;
    },
    setTenantConfig: (
      state,
      payload: {
        apiUrl: string;
        cdnBaseUrl: string;
        tagName: string;
        features: {};
        tenantId: string | undefined;
        code: string;
      }
    ) => {
      state.apiUrl = payload.apiUrl;
      state.cdnBaseUrl = payload.cdnBaseUrl;
      state.tagName = payload.tagName;
      state.features = payload.features;
      state.tenantId = payload.tenantId;
      state.code = payload.code;
    },
    setThemeConfig: (
      state,
      payload: {
        theme: Theme;
        login: LoginConfig;
        toolbar: ToolbarConfig;
        sidebar: SidebarConfig;
      }
    ) => {
      state.theme = payload.theme;
      state.login = payload.login;
      state.toolbar = payload.toolbar;
      state.sidebar = payload.sidebar;
    },
  },

  actions: {
    loadTenantTheme: async ({ commit }, tenantConf: TenantConfig | undefined) => {
      const hostname = window.location.host;
      commit('setLoadingTheme', true);
      try {
        if (!tenantConf) {
          throw new Error(`Cannot resolve tenant for host ${hostname}`);
        }

        const themeConf = tenantConf.themes.find((t) => t.name === 'admin');
        if (!themeConf) {
          throw new Error('Tenant is incorrectly setup');
        }

        document.title = themeConf.title;
        replaceFavicon('favicon', themeConf.favicon);

        commit('setTenantConfig', {
          apiUrl: tenantConf.apiUrls.admin,
          cdnBaseUrl: tenantConf.cdnBaseUrl,
          tagName: tenantConf.tagName,
          features: tenantConf.features,
          tenantId: tenantConf.id,
          code: tenantConf.code,
        });

        commit('setThemeConfig', {
          theme: themeConf.colorScheme,
          sidebar: themeConf.sidebar,
          toolbar: themeConf.toolbar,
          login: themeConf.login,
        });

        commit('setLoadingTheme', false);
        commit('setThemeLoaded', true);
      } catch (err) {
        commit('setLoadingTheme', false);
        console.error(err);
        throw err;
      }
    },
    getSellersJson: async ({ state, commit }) => {
      commit('setLoader', true, { root: true });
      await publiftApi
        .get(`/public-api/tenants/${state.tenantId}/sellersjson`)
        .then(({ data }) => saveAs(new Blob([JSON.stringify(data, null, 2)], { type: 'text/plain' }), 'sellers_json'))
        .catch((err) => console.error(err));
      commit('setLoader', false, { root: true });
    },
  },
};
