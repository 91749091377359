<template>
  <v-main>
    <v-progress-linear indeterminate v-if="isLoading" color="primary" class="mt-0" height="4" />
    <!-- when fill-height is set to true on the v-container element, it will align the page vertically. -->
    <v-container fluid :fill-height="shouldFillHeight" class="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-container>
  </v-main>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';

export default (Vue as VueConstructor<Vue>).extend({
  data: () => ({
    paths: ['/login', '/error'],
  }),
  computed: {
    shouldFillHeight(): boolean {
      return this.paths.some((path) => this.$route.path === path);
    },
    ...mapGetters(['isLoading']),
  },
});
</script>

<style>
.core-view {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
