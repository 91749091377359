<template>
  <v-fade-transition mode="out-in">
    <v-app-bar clipped-left app color="toolbar" :dark="darkTheme" :height="height">
      <v-app-bar-nav-icon
        @click="setSidebarOpen(!sidebarOpen)"
        :aria-label="`${sidebarOpen ? 'Close' : 'Open'} Sidebar Button`"></v-app-bar-nav-icon>
      <v-img :src="logoImage" contain max-height="40px" class="menu-logo" style></v-img>
      <v-spacer></v-spacer>

      <v-menu bottom left :nudge-bottom="40">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="getSellersJson">
            <v-list-item-avatar>
              <v-icon>mdi-file-download</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Download Sellers.JSON</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="confirmLogout(true)" aria-label="Logout Button" class="text-center">
        <v-icon>mdi-logout</v-icon>
        <confirmation-box
          :is-open="signOut"
          :message="logoutMessage"
          :persistent="true"
          :textPosition="'center'"
          :title="logoutTitle"
          @on-click-no="confirmLogout(false)"
          @on-click-yes="logout"
          accept-action-aria-label="Confirm Logout"
          reject-action-aria-label="Reject Logout"
          style="display: none" />
      </v-btn>
    </v-app-bar>
  </v-fade-transition>
</template>

<script lang="ts">
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Vue, { VueConstructor } from 'vue';
import ConfirmationBox from '@/components/ui/ConfirmationBox.vue';

export default (Vue as VueConstructor<Vue>).extend({
  data: () => ({
    title: 'Admin Portal',
    height: 50,
    signOut: false,
    logoutTitle: 'Log out',
    logoutMessage: 'Are you sure you want to log out?',
  }),
  props: {
    darkTheme: {
      type: Boolean,
      default: true,
    },
    logoImage: {
      type: String,
    },
  },
  components: {
    ConfirmationBox,
  },
  inject: ['dashboardConfig'],
  computed: {
    ...mapGetters(['sidebarOpen']),
    routeName(): string | undefined {
      return this.$route.meta && (this.$route.meta.displayName || this.$route.meta.name);
    },
  },
  methods: {
    logout(): void {
      this.$auth.logout({
        logoutParams: {
          returnTo: `${window.location.origin}/login`,
        },
      });
    },
    confirmLogout(logout: boolean): void {
      logout ? (this.signOut = true) : (this.signOut = false);
    },
    ...mapMutations(['setSidebarOpen']),
    ...mapActions('tenants', ['getSellersJson']),
  },
});
</script>

<style>
.menu-logo {
  height: 100%;
  width: auto;
  margin: 0;
  max-width: 150px;
}
</style>
