import axios, { AxiosError } from 'axios';
import { ITenant, IDashboardConfig, ThemeConfig } from '@publift-libs/shared';

export type TenantConfig = ITenant & { dashboardConfig: IDashboardConfig };

export type Theme = ThemeConfig['colorScheme'];

export interface LoginConfig {
  logo: string;
}

export interface ToolbarConfig {
  logo: string;
  dark: boolean;
}

export interface SidebarConfig {
  dark: boolean;
}

export interface TenantFeatures {
  cmp?: boolean;
  confiant?: boolean;
  blockthrough?: boolean;
  gaPrebidAnalytics?: boolean;
  amazonUam?: {
    enabled: boolean;
    pubID?: string;
  };
  identity?: boolean;
  quickstart?: boolean;
  experiments?: boolean;
  supplyChain?: boolean;
  legacyAkamaiName?: boolean;
  useJira?: boolean;
  id5?: {
    enabled: boolean;
    partnerId: number;
  };
}

export interface TenantState {
  features: TenantFeatures;
  loadingTheme: boolean;
  themeLoaded: boolean;
  theme?: Theme;
  apiUrl: string;
  cdnBaseUrl: string;
  tagName: string;
  code: string;
  login?: LoginConfig;
  toolbar?: ToolbarConfig;
  sidebar?: SidebarConfig;

  tenantId: string | undefined;
}

export const fetchTenantConfig = async (hostname: string): Promise<TenantConfig> => {
  try {
    const resp = await axios.get(`/public-api/tenants/resolve/${hostname}?theme=true`);
    const tenantConf = resp.data;
    if (!tenantConf) {
      throw new Error(`Cannot resolve tenant for host ${hostname}`);
    }

    return tenantConf;
  } catch (err) {
    console.error(err);
    const axiosError = err as AxiosError;
    if (axiosError?.response?.data) {
      throw axiosError.response.data;
    }
    throw err;
  }
};
