import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';

export interface INetwork {
  name: string;
  code: string;
  prebidModule?: string;
  commission: number;
}

export interface INetworkState {
  networks: INetwork[];
}

export const networks: Module<INetworkState, RootState> = {
  namespaced: true,
  state: {
    networks: [],
  },
  getters: {
    networks: (state) => state.networks,
    getNetworkByCode: (state) => (filteredNetworkCode) =>
      state.networks.find((network) => network.code === filteredNetworkCode),
  },
  mutations: {
    setNetworks: (state, payload: INetwork[]) => {
      state.networks = payload;
    },
  },
  actions: {
    loadNetworks: async ({ commit, state }, forceRefresh = false) => {
      if (state.networks.length === 0 || forceRefresh) {
        try {
          const { data: allNetworks }: { data: INetwork[] } = await publiftApi.get('/v2/networks');
          const formatted = allNetworks.map((network) => {
            return {
              ...network,
              searchString: `${network.name}(${network.code})`,
            };
          });
          commit('setNetworks', formatted);
        } catch (error) {
          console.error(error);
        }
      }
      return;
    },

    createNetworks: ({}, payload) => {
      return publiftApi.post('/v2/networks', { ...payload });
    },
  },
};
