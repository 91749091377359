import Router from 'vue-router';
import { AnalyticsInstance } from 'analytics';
import { IDashboardConfig } from '@publift-libs/shared';
import { makeRoutes, v3Redirects } from './routes';
import { authGuard } from '@/auth/authGuard';

export const getRouter = (analytics: AnalyticsInstance, _config: IDashboardConfig): Router => {
  const router = new Router({
    routes: makeRoutes(),
    mode: 'history',
  });

  router.beforeEach(authGuard);
  router.beforeEach(v3Redirects);

  router.afterEach(() => {
    analytics.page();
  });

  return router;
};
