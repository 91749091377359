/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { accounts } from './modules/accounts';
import { adsize } from './modules/adsize';
import { adunits } from './modules/adunits';
import { clients } from './modules/clients';
import { commissions } from './modules/commissions';
import { dfpNetworks } from './modules/dfpNetworks';
import { events } from './modules/events';
import { fuses } from './modules/fuses';
import { googleAnalytics } from './modules/google-analytics';
import { manualReporting } from '@/store/modules/manual-reporting';
import { miscIab } from './modules/miscs';
import { networks } from './modules/networks';
import { tags } from './modules/tags';
import { tenants } from './modules/tenants';
import { users } from './modules/users';

Vue.use(Vuex);

export interface RootState {
  sidebarOpen: boolean;
  isLoading: boolean;
  token: string;
}

const store: StoreOptions<RootState> = {
  getters: {
    sidebarOpen: (state) => state.sidebarOpen,
    isLoading: (state) => state.isLoading,
    getToken: (state) => state.token,
  },
  mutations: {
    setSidebarOpen(state, payload) {
      state.sidebarOpen = payload;
    },
    setLoader(state, payload) {
      state.isLoading = payload;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    // This handles the background scroll when a dialog is open
    setOverlayScroll: ({}, payload: string) => {
      document.documentElement.style.overflow = payload;
    },
    setLoader: ({ commit }, payload: string) => {
      commit('setLoader', payload);
    },
  },
  state: {
    sidebarOpen: false,
    isLoading: false,
    token: '',
  },
  modules: {
    accounts,
    adsize,
    adunits,
    clients,
    commissions,
    dfpNetworks,
    events,
    fuses,
    googleAnalytics,
    manualReporting,
    miscIab,
    networks,
    tags,
    tenants,
    users,
  },
};

export type StoreModules = typeof store.modules;

export default new Vuex.Store(store);
