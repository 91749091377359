import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import { AxiosError } from 'axios';

interface IAdsNetworks {
  name: string;
  allowed: boolean;
  data: object;
}

export interface IAdSize {
  name: string;
  width?: number;
  height?: number;
  fluid: boolean;
  networks?: IAdsNetworks[];
}

export interface AdSizeState {
  adsizes?: IAdSize[];
  loading: boolean;
  error: boolean;
  errorMsg: string;
}

export const adsize: Module<AdSizeState, RootState> = {
  namespaced: true,
  state: {
    adsizes: [],
    loading: false,
    error: false,
    errorMsg: '',
  },
  getters: {
    adsizes: (state) => state.adsizes,
    loading: (state) => state.loading,
    error: (state) => state.error,
    errorMsg: (state) => state.errorMsg,
  },
  mutations: {
    setAdSize: (state, payload: IAdSize[]) => {
      state.adsizes = payload;
    },
    setLoading: (state, payload: boolean) => {
      state.loading = payload;
    },
    setError: (state, payload: boolean) => {
      state.error = payload;
    },
    setErrorMsg: (state, payload: string) => {
      state.errorMsg = payload;
    },
  },
  actions: {
    getAdSizesFromAPI: async ({ commit }) => {
      commit('setLoading', true);
      const { data: allAdsizes }: { data: IAdSize[] } = await publiftApi.get('/v2/ad-sizes', {
        params: {
          limit: 10000,
        },
      });
      commit('setAdSize', allAdsizes);
      commit('setLoading', false);
      return;
    },
    createAdSize: async ({ commit }, payload) => {
      try {
        await publiftApi.post('/v2/ad-sizes', { ...payload });
        commit('setError', false);
        commit('setErrorMsg', '');
      } catch (e) {
        commit('setError', true);
        commit('setErrorMsg', e instanceof AxiosError ? e.response?.data : {});
      }
      return;
    },
    setLoading: ({ commit }, payload: boolean) => {
      commit('setLoading', payload);
    },
  },
};
