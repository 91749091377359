import Vue from 'vue';
import {
  Auth0Client,
  createAuth0Client,
  IdToken,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginOptions,
  PopupLoginOptions,
  Auth0ClientOptions,
} from '@auth0/auth0-spa-js';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance: Auth0Service;

type Auth0PluginOptions = Auth0ClientOptions & {
  onRedirectCallback?: (appState: any) => void;
  redirectUri?: string;
};

type UserProfile = {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  org_id: string;
  picture: string;
  sub: string;
  updated_at: string;
};

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export type Auth0Service = {
  isAuthenticated: boolean;
  loading: boolean;
  user: UserProfile;
  getTokenSilently(o: GetTokenSilentlyOptions): Promise<string>;
  getClient(): Auth0Client | null;
  loginWithRedirect(o: RedirectLoginOptions);
  logout(options?: LogoutOptions);
  $watch(o: string, cb);
};

interface Auth0State {
  loading: boolean;
  isAuthenticated: boolean;
  user: IdToken;
  auth0Client: Auth0Client;
  popupOpen: boolean;
  error: Error | null;
}

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: {
  onRedirectCallback?: (appState: any) => void;
  redirectUri?: string;
  [key: string]: any;
}) => {
  if (instance) {
    return instance;
  }

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null as unknown as Auth0Client,
        popupOpen: false,
        error: null,
      } as Auth0State;
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options: PopupLoginOptions, config?: any) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser<UserProfile>();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e as Error;
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser<UserProfile>();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser<UserProfile>();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e as Error;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: RedirectLoginOptions) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o: GetTokenSilentlyOptions) {
        return await this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */
      async getTokenWithPopup(o: GetTokenWithPopupOptions) {
        return await this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o: LogoutOptions) {
        return this.auth0Client.logout(o);
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.auth0Client = await createAuth0Client({
        ...options,
        authorizationParams: {
          redirect_uri: redirectUri,
          ...options.authorizationParams,
        },
      });

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        console.log(e);
        this.error = e as Error;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser<UserProfile>();
        this.loading = false;
      }
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue: any, options: Auth0PluginOptions) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
