import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';
import store from '../../store';
import { replaceArrayItems } from '@/utils/util';
import { User } from './users';
import { IAccount, AccountType } from '@publift-libs/shared';

export interface Account {
  tenantId: string;
  name: string;
  domain: string;
  dealCommission: number;
  code: string;
  type: AccountType;
  status: 'signed' | 'inprogress' | 'live' | 'churned' | 'live_mcm';
  archived: boolean;
  client: string;
  iab: string[];
  admNetworkId: string;
  childAdmNetworkId?: string;
  partners: IFusePartners;
  networks: INetworkConfig[];
  prebid: {
    modules: string[];
  };
  revisionNumber?: number;
  updatedAt?: string;
  createdAt?: string;
  firstTransactionDate?: Date;
  users?: string[];
  otherDomains?: string[];
  allowGamAdUnitCodeReuse?: boolean;
  requireZoneCodes?: boolean;
  tagId?: number;
}

export const onboardingStatusMap = {
  signed: 'Signed',
  inprogress: 'Setup In Progress',
  live: 'Live',
  churned: 'Churned',
  live_mcm: 'Live MCM',
};

export interface IBreakpointOptions {
  xl: IBreakpointSettings;
  l: IBreakpointSettings;
  m: IBreakpointSettings;
  s: IBreakpointSettings;
  xs: IBreakpointSettings;
}
export interface IBreakpointSettings {
  enabled: boolean;
  minimum_width?: number;
  headerbidding_enabled?: boolean;
  headerbidding_timeout?: number;
}

export interface BasePartner {
  networkAuthId?: string;
  enabled?: boolean;
}
export interface RubiconPartner extends BasePartner {
  primaryPublisherId: string;
  siteIds?: number;
}

export interface PubmaticPartner extends BasePartner {
  id: number;
  domain: string;
  desktop?: number;
  mobile?: number;
}
export interface AppNexusPartner extends BasePartner {
  id: string;
  siteId?: string;
}

export interface AdxPartner extends BasePartner {
  lineItemId?: string;
}

export interface IXPartner extends BasePartner {
  siteIds: number[];
}

export interface IOpenXPartner extends BasePartner {
  siteIds: number[];
}

export interface ITripleliftPartner extends BasePartner {
  placementIds: number[];
  inventoryCodePrefix: string;
  additionalInventoryCodeSizes: string[];
}

export interface ICriteoPartner extends BasePartner {
  primarySiteId: string;
  siteIds?: string[];
  placementIds?: string[];
  adblockPlacementIds?: string[];
}

export interface IDistrictMPartner extends BasePartner {
  primarySiteId: string;
  siteIds?: string[];
  placementIds?: string[];
}

export interface UnfilledPartner extends BasePartner {
  lineItemId?: string;
  creativeId?: string;
  adUnitId?: string;
}

export interface IFusePartners {
  rubicon?: RubiconPartner;
  appnexus?: AppNexusPartner;
  adx?: AdxPartner;
  unfilled?: UnfilledPartner;
  pubmatic?: PubmaticPartner;
  ix?: IXPartner;
  districtm?: IDistrictMPartner;
  criteo?: ICriteoPartner;
  openx?: IOpenXPartner;
  triplelift?: ITripleliftPartner;
  [network: string]: BasePartner | undefined;
}

export const defaultPartners = (): IFusePartners =>
  ({
    rubicon: {
      enabled: false,
    },
    pubmatic: {
      enabled: false,
    },
    appnexus: {
      enabled: false,
    },
    ix: {
      enabled: false,
    },
    criteo: {
      enabled: false,
    },
    districtm: {
      enabled: false,
    },
    adx: {
      enabled: false,
    },
    openx: {
      enabled: false,
    },
    triplelift: {
      enabled: false,
    },
  }) as IFusePartners;

export interface INewAccount {
  name: string;
  code: string;
  type: AccountType;
  status: 'signed' | 'inprogress' | 'live' | 'churned' | 'live_mcm';
  client: string;
  domain: string;
  otherDomains?: string[];
  dealCommission: number;
  iab: string[];
  admNetworkId: string;
  childAdmNetworkId?: string;
  archived: boolean;
  networks: INetworkConfig[];
  revisionNumber?: number;
  firstTransactionDate?: Date;
  users?: string[];
  allowGamAdUnitCodeReuse?: boolean;
  requireZoneCodes?: boolean;
  tagId?: number;
}
export const networksList = [
  'rubicon',
  'pubmatic',
  'appnexus',
  'adx',
  'ix',
  'criteo',
  'districtm',
  'openx',
  'triplelift',
];

/**
 * Builds a request in the schema required for the backend to create a new account.
 */
export const buildAccountApiRequest = async (account: Account): Promise<INewAccount> => {
  const {
    name,
    code,
    client,
    type,
    status,
    iab,
    domain,
    otherDomains,
    dealCommission,
    prebid,
    admNetworkId,
    childAdmNetworkId,
    revisionNumber,
    networks,
    archived,
    firstTransactionDate,
    users,
    allowGamAdUnitCodeReuse,
    requireZoneCodes,
    tagId,
  } = account;
  const formattedNetworks: INetworkConfig[] = await Promise.all(
    prebid!.modules.map(async (network) => {
      const currentNetworkSettings = networks.find((i) => i.networkId === network);
      const networkSettings: INetworkConfig = {
        tenantId: account.tenantId,
        accountCode: account.code,
        networkId: network,
        data: {},
        revisionNumber: currentNetworkSettings?.revisionNumber || 1,
        createdAt: currentNetworkSettings?.createdAt || undefined,
        updatedAt: currentNetworkSettings?.updatedAt || undefined,
      };
      // This formats the networks to the format expected in the Backend.
      if (networksList.includes(network)) {
        // @networksList refers to networks which have additional settings.
        const networkInfo = account.partners[network];
        if (networkInfo) {
          networkSettings.networkAuthId = networkInfo.networkAuthId;

          // Don't need these values inside the data object.
          delete networkInfo.enabled;
          delete networkInfo.networkAuthId;

          networkSettings.data = { ...account.partners[network] };
        }
      }
      return networkSettings;
    })
  );
  const req: INewAccount = {
    name,
    code,
    client,
    type,
    status,
    domain: domain.replace(/^(http|https):\/\//, ''), // strip off http/https prefix from domain url
    otherDomains: otherDomains?.map((domain) => domain.replace(/^(http|https):\/\//, '')),
    dealCommission,
    iab,
    admNetworkId,
    childAdmNetworkId: childAdmNetworkId || undefined,
    archived: archived || false,
    revisionNumber: revisionNumber || 1,
    networks: formattedNetworks,
    firstTransactionDate,
    users,
    allowGamAdUnitCodeReuse,
    requireZoneCodes,
    tagId,
  };

  return req;
};

export const defaultAccountSettings: Account = {
  tenantId: '',
  name: '',
  code: '',
  type: AccountType.FUSE,
  status: 'inprogress',
  client: '',
  iab: [],
  archived: false,
  domain: '',
  otherDomains: [],
  dealCommission: 0,
  admNetworkId: '',
  childAdmNetworkId: '',
  partners: defaultPartners(),
  prebid: {
    modules: [] as string[],
  },
  networks: [] as INetworkConfig[],
  revisionNumber: 1,
  users: [] as string[],
};

export interface INetworkConfig {
  tenantId: string;
  accountCode: string;
  networkId: string;
  networkAuthId?: string;
  data: { [key: string]: unknown };
  revisionNumber?: number;
  updatedAt?: string;
  createdAt?: string;
}

/**
 * Network Specific Configs
 */

export interface IRubiconNetworkConfig extends INetworkConfig {
  data: {
    domain: string;
    primaryPublisherId: number;
    primarySiteId?: number;
    defaultZoneId?: number;
    siteIds?: number[];
    zoneIds?: number[];
  };
}

export interface IPubmaticNetworkConfig extends INetworkConfig {
  data: {
    domain: string;
    primaryPublisherId: number;
    primarySiteId?: number;
    publisherIds: number[];
    siteIds: number[];
    tagIds: number[];
  };
}

export interface IAppNexusNetworkConfig extends INetworkConfig {
  data: {
    primaryPublisherId: number;
    primaryPlacementGroupId?: number;
    publisherIds?: number[];
    placementGroupIds?: number[];
    placementIds?: number[];
  };
}

export interface IAdxNetworkConfig extends INetworkConfig {
  data: {
    sites: string[];
    networkPartner: string;
  };
}

export interface IXNetworkConfig extends INetworkConfig {
  data: {
    primarySiteId: number;
    siteIds: number[];
  };
}

export interface ICriteoNetworkConfig extends INetworkConfig {
  data: {
    primarySiteId: string;
    siteIds: string[];
    placementIds: string[];
    adblockPlacementIds: string[];
  };
}

export interface IDistrictMNetworkConfig extends INetworkConfig {
  data: {
    primarySiteId: string;
    siteIds: string[];
    placementIds: string[];
  };
}

export interface IPrebidPartner {
  name: string;
  code: string;
  id?: string;
}

export interface IDropdown {
  text: string;
  value?: string | number;
}

export interface AccountsState {
  accounts: Account[];
  loading: boolean;
  step: number;
  accountToEdit: Account;
  editAccountMode: boolean;
  prebidPartners: IPrebidPartner[];
  partnerList: string[];
  approvedPartners: IPrebidPartner[];
  loadingPartners: boolean;
  accountTypes: IDropdown[];
  loadingUsers: boolean;
  users: object[];
}
export interface IAdmUpdateRequest {
  account: string;
  networkCode: string;
}

export const accounts: Module<AccountsState, RootState> = {
  namespaced: true,
  state: {
    accounts: [],
    loading: false,
    step: 1,
    accountToEdit: {} as Account,
    editAccountMode: false,
    prebidPartners: [],
    approvedPartners: [],
    partnerList: ['rubicon', 'criteo', 'appnexus', 'pubmatic', 'ix', 'districtm', 'openx', 'triplelift'],
    loadingPartners: false,
    accountTypes: [
      { text: 'Classic', value: AccountType.FUSE },
      { text: 'Consulting', value: AccountType.CONSULTING },
      { text: 'Premium', value: AccountType.MISC },
      { text: 'New', value: AccountType.NEW },
      { text: 'Licensing', value: AccountType.LICENSING },
      { text: 'Enterprise', value: AccountType.ENTERPRISE },
    ],
    loadingUsers: false,
    users: [],
  },
  getters: {
    accounts: (state) => state.accounts,
    getAccountByCode: (state) => (filteredAccounts) => {
      return filteredAccounts.map((code) => {
        return state.accounts!.find((account) => account.code === code);
      });
    },
    getAccountByClientCode: (state) => (filteredAccounts) => {
      return filteredAccounts.map((code) => {
        return state.accounts!.filter((account) => account.client === code);
      });
    },
    loading: (state) => state.loading,
    step: (state) => state.step,
    accountToEdit: (state) => state.accountToEdit,
    editAccountMode: (state) => state.editAccountMode,
    prebidPartners: (state) => state.prebidPartners,
    approvedPartners: (state) => state.approvedPartners,
    loadingPartners: (state) => state.loadingPartners,
    accountTypes: (state) => state.accountTypes,
    loadingUsers: (state) => state.loadingUsers,
    users: (state) => state.users,
  },
  mutations: {
    setAccounts: (state, payload: Account[]) => {
      replaceArrayItems(state.accounts, payload);
    },
    setLoading: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = payload;
    },
    setLoadingPartners: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingPartners = payload;
    },
    setStep: (state, payload: number) => {
      // eslint-disable-next-line no-param-reassign
      state.step = payload;
    },
    setAccountToEdit: (state, payload: Account) => {
      // eslint-disable-next-line no-param-reassign
      state.accountToEdit = payload;
    },
    setEditAccountMode: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.editAccountMode = payload;
    },
    setApprovedPartners: (state, payload: IPrebidPartner[]) => {
      // eslint-disable-next-line no-param-reassign
      state.approvedPartners = payload;
    },
    setPrebidPartners: (state, payload: IPrebidPartner[]) => {
      // eslint-disable-next-line no-param-reassign
      state.prebidPartners = payload;
    },
    setLoadingUsers: (state, payload: boolean) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingUsers = payload;
    },
    setUsers: (state, payload: object[]) => {
      // eslint-disable-next-line no-param-reassign
      state.users = payload;
    },
  },
  actions: {
    getAccountsFromAPI: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];
      const [{ data: accounts }, { data: akamaiAccounts }] = await Promise.all([
        publiftApi.get(`/webapi/tenants/${tenantId}/accounts`, {
          params: {
            limit: 100000,
          },
        }),
        publiftApi.get(`/webapi/tenants/${tenantId}/account-experiment/accounts`),
      ]);
      const allAccounts = accounts.map((account) => {
        const status = akamaiAccounts.find((akamaiAccount) => account.code === akamaiAccount.accountCode);
        return {
          ...account,
          abStatus: status ? status.status : undefined,
          abPercent: status ? status.experimentPercent : 0,
        };
      });
      const formattedAccounts = await Promise.all(
        allAccounts.map((account) => {
          const networkPartners = defaultPartners();
          const enabledPartners: string[] = [];
          if (account.networks) {
            for (const network of account.networks) {
              enabledPartners.push(network.networkId);
              networkPartners[network.networkId] = {
                ...network.data,
                networkAuthId: network.networkAuthId,
              };
            }
          }
          return {
            ...account,
            searchString: `${account.name} (${account.code})`, // this is used in the filtering component.
            partners: networkPartners,
            prebid: {
              modules: enabledPartners,
            },
          };
        })
      );
      commit('setAccounts', formattedAccounts);
      return;
    },
    createAccount: (_, newAccount: IAccount) => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.post(`/webapi/tenants/${tenantId}/accounts/legacy`, newAccount);
    },
    updateAccount: (_, account: IAccount) => {
      const tenantId = store.getters['tenants/tenantId'];
      return publiftApi.post(`/webapi/tenants/${tenantId}/accounts/legacy`, account);
    },
    setLoading: ({ commit }, payload: boolean) => {
      commit('setLoading', payload);
    },
    setLoadingPartners: ({ commit }, payload: boolean) => {
      commit('setLoadingPartners', payload);
    },
    setStep: ({ commit }, payload: number) => {
      commit('setStep', payload);
    },
    getAccountByCode: ({ state }, payload: string) => {
      return state.accounts.find((account) => account.code === payload);
    },
    setAccountToEdit: ({ commit }, payload: Account) => {
      commit('setAccountToEdit', payload);
    },
    setEditAccountMode: ({ commit }, payload: boolean) => {
      commit('setEditAccountMode', payload);
    },
    loadApprovedPrebidPartners: async ({ commit, dispatch }) => {
      try {
        dispatch('setLoadingPartners', true);
        const tenantId = store.getters['tenants/tenantId'];
        const approvedPartners = await publiftApi.get(`/webapi/tenants/${tenantId}/tags/prebid-partners`);
        commit('setApprovedPartners', approvedPartners.data);
      } catch (error) {
        console.error(error);
      }
    },

    loadPrebidPartners: async ({ commit, state, dispatch }) => {
      const thirdPartyNetworks = store.getters['dfpNetworks/thirdPartyNetworks'] // Get a list of the networks already set up, using the network code as the value
        .map((thirdPartySettings) => thirdPartySettings.network);

      const networksAlreadySetup: IPrebidPartner[] = [];

      const networksNotSetup = state.approvedPartners //  Filters through all approved prebid partners
        .filter((network) => {
          if (thirdPartyNetworks.includes(network.code)) {
            // If a network is already setup this will return true
            networksAlreadySetup.push(network); // Add network to array of networks already set up,
            return;
          } else {
            // If a network is not setup this will return the network
            return network;
          }
        })
        .map((network) => {
          // Maps networks with disabled attribute if they need to have been previously set up and need extra configuration
          if (!state.partnerList.includes(network.code)) {
            // @PartnerList refers to partners/networks which need extra configuration. Eg: Rubicon, IX, AppNexus, Criteo..
            return network;
          }
          const formattedNetwork = {
            code: network.code,
            name: network.name,
            //disabled: true,
          };
          return formattedNetwork;
        });

      const prebidPartners = [...networksAlreadySetup, ...networksNotSetup];
      commit('setPrebidPartners', prebidPartners);
      dispatch('setLoadingPartners', false);
    },

    setLoadingUsers: ({ commit }, payload: boolean) => {
      commit('setLoadingUsers', payload);
    },
    loadUsers: async ({ commit, dispatch }, payload: Account) => {
      try {
        dispatch('setLoadingUsers', true);
        const users = (
          await publiftApi.get('/v2/users/', {
            params: {
              showArchived: false,
              showInactive: false,
              limit: 100000,
            },
          })
        ).data.filter((user: User) => user.clients?.includes(payload.client));
        commit('setUsers', users);
        dispatch('setLoadingUsers', false);
        return users;
      } catch (error) {
        console.error(error);
      }
      return [];
    },
  },
};
