/* eslint-disable no-param-reassign */
import { Module } from 'vuex';
import { IGa4Config, IGa4Property } from '@publift-libs/shared';
import { AxiosError, AxiosResponse } from 'axios';
import { publiftApi } from '@/services/axios';
import store from '@/store';
import { RootState } from '..';

interface IGoogleAnalyticsState {
  isConfigLoading: boolean;
  mappedConfigs: IGa4Config[];
  isPropertyLoading: boolean;
  availableProperties: IGa4Property[];
  isSaving: boolean;
  saveConfigErrors?: string[];
}

export const googleAnalytics: Module<IGoogleAnalyticsState, RootState> = {
  namespaced: true,
  state: {
    isConfigLoading: false,
    mappedConfigs: [],
    isPropertyLoading: false,
    availableProperties: [],
    isSaving: false,
    saveConfigErrors: undefined,
  },
  getters: {
    isConfigLoading: (state) => state.isConfigLoading,
    mappedConfigs: (state) => (state.isConfigLoading ? undefined : state.mappedConfigs),
    isPropertyLoading: (state) => state.isPropertyLoading,
    availableProperties: (state) => (state.isPropertyLoading ? undefined : state.availableProperties),
    isSaving: (state) => state.isSaving,
    saveConfigErrors: (state) => state.saveConfigErrors,
  },
  mutations: {
    setIsConfigLoading: (state, payload: boolean) => {
      state.isConfigLoading = payload;
    },
    setMappedConfigs: (state, payload: IGa4Config[]) => {
      state.mappedConfigs = payload;
    },
    setIsPropertyLoading: (state, payload: boolean) => {
      state.isPropertyLoading = payload;
    },
    setAvailableProperties: (state, payload: IGa4Property[]) => {
      state.availableProperties = payload;
    },
    setIsSaving: (state, payload: boolean) => {
      state.isSaving = payload;
    },
    setSaveConfigErrors: (state, payload: string[]) => {
      state.saveConfigErrors = payload;
    },
    clearSaveConfigErrors: (state) => {
      state.saveConfigErrors = undefined;
    },
  },
  actions: {
    fetchConfigs: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];

      commit('setIsConfigLoading', true);
      const { data } = await publiftApi.get<IGa4Config[]>(`/webapi/tenants/${tenantId}/account-ga-config`);
      commit('setMappedConfigs', data);
      commit('setIsConfigLoading', false);
    },
    fetchAvailableProperties: async ({ commit }) => {
      const tenantId = store.getters['tenants/tenantId'];

      commit('setIsPropertyLoading', true);
      const { data } = await publiftApi.get<IGa4Property[]>(
        `/webapi/tenants/${tenantId}/account-ga-config/available-properties`
      );
      commit('setAvailableProperties', data);
      commit('setIsPropertyLoading', false);
    },
    saveConfigs: async ({ commit, dispatch }, payload: Omit<IGa4Config, 'id' | 'tenantId'>[]) => {
      commit('setIsSaving', true);
      commit('clearSaveConfigErrors');

      const tenantId = store.getters['tenants/tenantId'];

      const saveConfigErrors: string[] = [];

      for (const configDto of payload) {
        try {
          await publiftApi.post(`/webapi/tenants/${tenantId}/account-ga-config`, configDto);
        } catch (e) {
          console.error(`Failed to save config: ${JSON.stringify(configDto)}`);
          saveConfigErrors.push(
            ((e as AxiosError).response as AxiosResponse).data.message || (e as AxiosError).message
          );
          console.error(e);
        }
      }

      commit('setIsSaving', false);
      if (saveConfigErrors.length > 0) {
        commit('setSaveConfigErrors', saveConfigErrors);
      }

      // Get the latest configs
      dispatch('fetchConfigs');
    },
    clearSaveConfigErrors: ({ commit }) => {
      commit('clearSaveConfigErrors');
    },
  },
};
