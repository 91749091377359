import { Module } from 'vuex';
import { RootState } from '..';
import { publiftApi } from '@/services/axios';

export interface Events {
  events: string;
}

export interface EventsState {
  events?: Events[];
}

export interface EventApiResponse {
  items: Events[];
}

export const events: Module<EventsState, RootState> = {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {
    events: (state) => state.events,
  },
  mutations: {
    setEvents: (state, payload: Events[]) => {
      state.events = payload;
    },
  },
  actions: {
    getEventsFromAPI: async ({ commit }) => {
      const { data: allEvents }: { data: Events[] } = await publiftApi.get('/v2/logs/events');
      commit('setEvents', allEvents);
      return;
    },
  },
};
