export function assertExhaustive(_value: never, message = 'Reached unexpected case in exhaustive switch'): Error {
  return new Error(message);
}

export interface ITreeViewProps {
  id: string | number;
  open?: boolean;
  name: string;
  children?: ITreeViewProps[];
  activatable?: boolean;
}

export function formatTreeData(items: {}): ITreeViewProps[] {
  let counter = 0;
  const treeTraversal = (value: unknown, key = 'undefined'): ITreeViewProps => {
    counter += 1;
    if (typeof value === 'object' && value !== null) {
      return {
        id: counter,
        name: `${key}`,
        children: Object.entries(value).map(([k, v]) => treeTraversal(v, k)),
      };
    } else {
      return { id: counter, name: `${key} : ${value}` };
    }
  };
  return treeTraversal(items).children!;
}

// Keeps array reference but replaces objects in array
export function replaceArrayItems<T>(target: T[], items: T[]): void {
  target.splice(0, Number.MAX_VALUE, ...items);
}
